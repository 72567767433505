import React, { useEffect, useRef, useState } from "react"
import {
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
  ImageStyle,
  Image,
  Dimensions,
  GestureResponderEvent,
  TouchableOpacity,
  Animated,
  Platform
} from "react-native"
import ContentLoader, { Rect, Circle, Path } from "react-content-loader/native"
import FadeIn from "react-native-fade-in-image"
import { observer } from "mobx-react-lite"
import { flatten } from "ramda"

import { color, typography, spacing } from "@app/theme"
import { Text } from "@app/components"
import { Catalog, Dealer } from "@app/models"
import { Feather } from "@expo/vector-icons"

interface OnPress {
  (offer: OfferCardProps, e: GestureResponderEvent): void
}
export interface OfferCardProps {
  style?: StyleProp<ViewStyle>
  title?: string | React.ReactNode
  subTitle?: string | React.ReactNode
  catalog?: Catalog
  onOfferPress?: OnPress
  onStorePress?: OnPress
  isLoading?: boolean
  hovered?: boolean
  showWarning?: boolean
}

export const OfferCard = observer((props: OfferCardProps) => {
  const containerStyles = flatten([CONTAINER, props.style])
  const scaleAnimation = useRef(new Animated.Value(1)).current
  const [availableImageWidth, setAvailableImageWidth] = useState<number>(null)

  const onPress = (fn?: OnPress) => (e: GestureResponderEvent) => {
    if (fn) fn(props, e)
  }

  const scaleUp = () => {
    Animated.timing(scaleAnimation, {
      toValue: 1.02,
      duration: 150,
      useNativeDriver: Platform.OS !== "web"
    }).start()
  }
  const scaleReset = () => {
    Animated.timing(scaleAnimation, {
      toValue: 1,
      duration: 150,
      useNativeDriver: Platform.OS !== "web"
    }).start()
  }
  useEffect(() => {
    if (props.hovered) {
      scaleUp()
    } else {
      scaleReset()
    }
  }, [props.hovered])

  const animatedStyles: Animated.WithAnimatedObject<ViewStyle> = {
    ...(Platform.OS === "web" &&
    typeof navigator !== "undefined" &&
    /Mobi|Android/i.test(navigator.userAgent) // disable scale animation on mobile web since it broke in iOS 18.3.1
      ? {}
      : { transform: [{ scale: scaleAnimation }] })
  }

  return props.isLoading ? (
    <OfferCardLoader style={props.style || CONTAINER} />
  ) : (
    <View style={containerStyles}>
      <TouchableOpacity onPress={onPress(props.onOfferPress)}>
        <Animated.View
          style={[SHADOW, animatedStyles]}
          onLayout={(event) => setAvailableImageWidth(event.nativeEvent.layout.width)}
        >
          {props.showWarning && (
            <View style={OFFER_CARD_WARNING_ICON}>
              <Feather style={WARNING_ICON} name="alert-triangle" />
            </View>
          )}
          <FadeIn>
            <Image
              style={[IMAGE, { minHeight: availableImageWidth / ASPECT_RATIO }]}
              source={{ uri: props.catalog?.images?.thumb }}
            />
          </FadeIn>
        </Animated.View>
        <Text preset="header" style={TITLE}>
          {props.title}
        </Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={onPress(props.onStorePress)}>
        <Text style={OFFER_CARD_SUB_TITLE}>{props.subTitle}</Text>
      </TouchableOpacity>
    </View>
  )
})
const OfferCardLoader = (props) => (
  <ContentLoader
    speed={2}
    width={props.style.width}
    viewBox={`0 0 195 350`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <Path d="M 7.354 0.344 C 4.534 1.1 1.994 3.27 0.691 6.037 L 0 7.504 V 285.953 l 0.838 1.662 c 1.416 2.809 3.418 4.546 6.205 5.385 c 1.717 0.517 2.037 0.519 90.546 0.451 l 88.823 -0.067 l 1.492 -0.599 c 2.088 -0.839 4.333 -2.971 5.4 -5.129 l 0.843 -1.703 V 7.493 l -0.843 -1.703 c -1.067 -2.158 -3.312 -4.29 -5.4 -5.129 l -1.492 -0.6 L 97.589 0.015 C 20.007 -0.03 8.588 0.014 7.354 0.344 z M 5.806 305.38 c -0.624 0.318 -1.49 1.021 -1.924 1.563 l -0.788 0.986 l -0.079 7.098 c -0.101 9.14 0.08 9.829 2.913 11.11 c 1.613 0.73 141.745 0.73 143.359 0 c 2.833 -1.281 3.013 -1.97 2.912 -11.11 l -0.079 -7.098 l -0.788 -0.986 c -0.434 -0.542 -1.299 -1.245 -1.924 -1.563 l -1.135 -0.577 H 6.942 l -1.136 0.577 z m 0.687 27.656 c -4.426 1.322 -5.372 13.589 -1.249 16.192 l 1.174 0.741 h 103.446 l 1.174 -0.741 c 4.144 -2.616 3.172 -14.956 -1.274 -16.185 c -1.181 -0.327 -102.179 -0.333 -103.27 -0.007 z" />
  </ContentLoader>
)

const BORDER_RADIUS = 10
const SHADOW: ViewStyle = {
  borderRadius: BORDER_RADIUS,
  backgroundColor: "transparent",
  shadowColor: color.palette.black,
  shadowOffset: {
    width: 0,
    height: 1
  },
  shadowOpacity: 0.16,
  shadowRadius: 16,
  elevation: 3
}

const CONTAINER: ViewStyle = {
  justifyContent: "flex-start",
  maxWidth: 220,
  marginBottom: spacing[6],
  width: Dimensions.get("window").width / 2
}
const ASPECT_RATIO = 250 / 380
const IMAGE: ImageStyle = {
  width: "100%",
  aspectRatio: ASPECT_RATIO,
  // resizeMode: "contain",
  resizeMode: "cover",
  borderRadius: BORDER_RADIUS
}

const OFFER_CARD_WARNING_ICON: ViewStyle = {
  position: "absolute",
  padding: 4,
  borderRadius: 20,
  zIndex: 1,
  backgroundColor: color.palette.gray5,
  right: 16,
  top: 16,
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const WARNING_ICON: TextStyle = {
  color: color.textWhite,
  fontSize: 16
}

const TITLE: TextStyle = {
  textTransform: "capitalize",
  ...(Dimensions.get("window").width <= 368 && { fontSize: 14 }),
  marginTop: 8,
  marginLeft: 0,
  marginBottom: 0
}

export const OFFER_CARD_SUB_TITLE: TextStyle = {
  fontFamily: typography.primary,
  fontSize: 14,
  ...(Dimensions.get("window").width <= 368 && { fontSize: 12 }),
  fontWeight: "500",
  // textTransform: "capitalize",
  color: color.palette.gray4,
  marginLeft: 0,
  marginTop: 2
}
